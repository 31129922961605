import { AppRoot } from "./components/AppRoot";


function App() {
  return (
    <div>
      <AppRoot />
    </div>
  );
}

export default App;
